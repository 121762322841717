<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs2 md2>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="date"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      persistent-hint
                      :value="computedDataInicial"
                      label="Data Inicial"
                      readonly
                      v-on="on"
                      outline
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="filtros.data_inicial"
                    locale="pt-br"
                    no-title
                    scrollable
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs2 md2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="date2"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      persistent-hint
                      :value="computedDataFinal"
                      label="Data Final"
                      readonly
                      v-on="on"
                      outline
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="filtros.data_final"
                    locale="pt-br"
                    no-title
                    scrollable
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs4 md4 v-if="currentUser.tipo === 'A' || currentUser.tipo === 'C'">
                <v-autocomplete
                  item-value="codigo"
                  item-text="razao_social"
                  v-model="filtros.unidade_codigo"
                  :items="unidades"
                  :label="currentUser.tipo === 'A' ? 'Canal' : 'Loja'"
                  placeholder=" "
                  outline
                  clearable
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs3 md3>
                <v-autocomplete
                  item-value="codigo"
                  item-text="descricao"
                  v-model="filtros.situacao"
                  :items="situacoes"
                  label="Situação"
                  placeholder=" "
                  outline
                  :return-object="false"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs1 md1>
                <v-btn fab dark color="primary" @click="getConsulta()">
                  <v-icon dark large>search</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  color="primary"
                  v-if="this.items.length > 0"
                  @click="printConsulta()"
                >
                  <v-icon dark large>print</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          item-key="id"
          :rows-per-page-items="[5,10,20,50,100]"
          dense
        >
          <template slot="no-data">
            <div class="text-xs-center">Sem registros.</div>
          </template>
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.codigo_abreviado }}</td>
              <td>{{ props.item.cliente_nome }}</td>
              <td>{{ props.item.cnpj_cpf_format }}</td>
              <td>{{ props.item.segurados_qtd}}</td>
              <td>{{ props.item.valor_total | currency}}</td>
              <td>{{ props.item.data_cadastro_format }}</td>
              <td v-if="props.item.situacao === 'ED'">Em digitação</td>
              <td v-if="props.item.situacao === 'AP'">Aguardando pagamento</td>
              <td v-if="props.item.situacao === 'PA'">Pago</td>
              <td v-if="props.item.situacao === 'AU'">Autorizado</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
          </template>

          <template v-slot:footer>
            <td :colspan="headers.length">
              <div class="text-xs-right">
                <b>Total R$ {{computedTotalVenda | currency}}</b>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import * as moment from 'moment'
const unidadeModule = () => import('./../../services/unidade')
const consultaModule = () => import('./../../services/consulta')
const reportModule = () => import('./../../services/reports')
import { mapGetters } from 'vuex'

export default {
  name: 'ConsultaContratos',
  data () {
    return {
      valid: true,
      search: '',
      date: new Date().toISOString().substr(0, 7),
      date2: new Date().toISOString().substr(0, 7),
      menu: false,
      menu2: false,
      unidades: [],
      filtros: {
        data_inicial: new Date().toISOString().substr(0, 10),
        data_final: new Date().toISOString().substr(0, 10),
        unidade_codigo: 0,
        situacao: '0'
      },
      headers: [
        { text: 'Contrato', align: 'left', value: '' },
        { text: 'Cliente', align: 'left', value: '' },
        { text: 'Documento', align: 'left', value: '' },
        { text: 'Seguros', align: 'left', value: '' },
        { text: 'Valor(R$)', align: 'left', value: '' },
        { text: 'Data de cadastro', align: 'left', value: '' },
        { text: 'Situação', align: 'left', value: 'situacao' }
      ],
      items: [],
      situacoes: [
        {
          codigo: '0',
          descricao: 'Todos'
        },
        {
          codigo: 'ED',
          descricao: 'Em digitação'
        },
        {
          codigo: 'AP',
          descricao: 'Aguardando pagamento'
        },
        {
          codigo: 'PA',
          descricao: 'Pago'
        },
        {
          codigo: 'Autorizado',
          descricao: 'Autorizado'
        },
      ]
    }
  },
  mounted () {
    this.getUnidades()
  },

  computed: {
    ...mapGetters(['currentUser']),
    computedDataInicial () {
      return this.filtros.data_inicial ? moment(this.filtros.data_inicial).format('DD/MM/YYYY') : ''
    },
    computedDataFinal () {
      return this.filtros.data_final ? moment(this.filtros.data_final).format('DD/MM/YYYY') : ''
    },
    computedTotalVenda () {
      if (this.items.length > 0) {
        return this.items
          .filter(e => parseFloat(e.valor_total) > 0)
          .map(element => parseFloat(element.valor_total))
          .reduce((total, valor) => {
            return total += parseFloat(valor)
          })
      } else {
        return 0
      }
    }

  },
  methods: {
    async getConsulta () {
      let consultaService = await consultaModule()
      let resp = await consultaService.getContratos(this.$axios, { ...this.filtros })
      if (resp.status === 200 && resp.data) {
        this.items = resp.data
      }
    },
    async printConsulta () {
      let reportService = await reportModule()
      let resp = await reportService.getContratosPDF(this.$axios, { ...this.filtros })
      if (resp.status === 200 && resp.data) {
        let blob = resp.data
        let link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${moment().format('DD-MM-YYYY_HH-mm-ss')}-consulta-contratos.pdf`
        document.body.append(link)
        link.click()
        link.remove()
        window.addEventListener('focus', () => URL.revokeObjectURL(link.href), { once: true })
      }
    },
    async getUnidades () {
      let unidadeService = await unidadeModule()
      if (this.currentUser.tipo === 'A') {
        let resp = await unidadeService.getAllCanais(this.$axios)
        if (resp.status === 200 && resp.data) {
          this.unidades = resp.data
          this.unidades.unshift({
            codigo: 0,
            razao_social: 'Todos'
          })
        }
      } else if (this.currentUser.tipo === 'C') {
        let resp = await unidadeService.getAllLojasByUser(this.$axios)
        if (resp.status === 200 && resp.data) {
          this.unidades = resp.data
          this.unidades.unshift({
            codigo: 0,
            razao_social: 'Todos'
          })
        }
      }
    }
  }
}
</script>